import { merge as _merge, get as _get, set as _set } from 'lodash-es';

import General from './General';
import { _selector, _pixelToEm } from './helpers';

const calculateHolderWidth = (mode, element, nrOfProducts, marginHorizontal) => {
  if (mode === 'nano') return 'auto';

  if (nrOfProducts === 1) {
    return `calc(100% / 1 - 1 * ${_pixelToEm(marginHorizontal)}em)`;
  }

  if (element.data.layout === 'horizontal') {
    return `calc(100% / 2 - 1 * ${_pixelToEm(marginHorizontal)}em)`;
  }

  return `calc(100% / 3 - 1 * ${_pixelToEm(marginHorizontal)}em)`;
};

export default (element, template) => {
  const { uid, type } = element;

  const selector = `${_selector(uid, type)} .om-product-holder`;
  const { desktop, mobile } = General(element, template, selector);
  const mq = {};

  const smartSizeType = _get(element, 'desktop.smartSize.type', 'fluid');

  if (smartSizeType === 'fluid') {
    const marginHorizontal = element.desktop.margin.left + element.desktop.margin.right;
    const nrOfProducts = _get(element, 'data.nrOfProducts', 1);
    const holderWidth = calculateHolderWidth(
      template.style.mode,
      element,
      nrOfProducts,
      marginHorizontal,
    );

    if (nrOfProducts < 3) {
      const mediaQueries = [
        {
          minWidth: 576,
          transform: 'none',
          display: 'contents',
          selector: `.om-swiper-wrapper`,
        },
      ];
      mediaQueries.forEach(({ minWidth, transform, display, selector }) => {
        const baseMqQuery = `["@media (min-width: ${minWidth}px)"]`;
        const transformQuery = baseMqQuery.concat(`.["${selector}"].transform`);
        _set(mq, transformQuery, {
          v: transform,
          i: true,
        });
        const displayQuery = baseMqQuery.concat(`.["${selector}"].display`);
        _set(mq, displayQuery, {
          v: display,
          i: true,
        });
      });
    }

    _merge(desktop, {
      [selector]: {
        width: { v: holderWidth, i: false },
      },
    });
  }

  const marginMobileHorizontal =
    (element.mobile.margin.left || element.desktop.margin.left) +
    (element.mobile.margin.right || element.desktop.margin.right);
  const holderWidthOnMobile = `calc(100% - ${_pixelToEm(marginMobileHorizontal)}em)`;
  // Product components are horizontally scrollable on mobile devices, no need to limit their width.
  _merge(mobile, {
    [selector]: {
      width: { v: holderWidthOnMobile, i: true },
    },
  });

  return {
    desktop,
    mobile,
    mq,
  };
};
